import React, { Component } from 'react';
import { Row, Col,Dropdown,DropdownToggle,DropdownMenu,DropdownItem,ButtonDropdown } from 'reactstrap';
import { Link } from 'react-router';
import {  GetInputField, ImageCard, ContentDrop, DeleteModal } from '../../../Elements';
import { FieldSelect } from '../../../Elements_V';
import { checkString, checkEmail } from '../../../../../formValidator';
import { toastFlashMessage, getDataUri } from '../../../../../utils';
import { imgStoragePath } from '../../../../../constants'
import { browserName } from 'react-device-detect';

export default class AddEditFinding extends Component{
  constructor(props){
    super(props);
    this.state = {
      dragOver: false,
      auditFinding:{},
      delModal: false,
      modalTitle:'',
      fileArr: [],
      picId: '',
      picIndex: '',
      error: {},
      damageType:[
        {value:"Dent",label:"Dent"},
        {value:"Scratch",label:"Scratch"},
        {value:"Nick",label:"Nick"},
        {value:"Gouge",label:"Gouge"},
        {value:"Erosion",label:"Erosion"},
        {value:"Corrosion",label:"Corrosion"},
        {value:"Puncture",label:"Puncture"},
        {value:"Lightning Strike",label:"Lightning Strike"},
        {value:"Hail strike",label:"Hail strike"},
        {value:"Wave",label:"Wave"},
        {value:"Crack",label:"Crack"},
        {value:"Others", label:"Others"}
      ],
      classifications:[
        {value:"Major",label:"Major"},
        {value:"Minor",label:"Minor"},
      ],
      category:[
        {value:"A",label:"A"},
        {value:"B",label:"B"},
        {value:"C",label:"C"}
      ],
      repairType:[
        {value:"Doubler",label:"Doubler"},
        {value:"Composite",label:"Composite"}, 
      ]
        
        


     
    }
  }
  componentDidMount(){
    this.setState({
      auditFinding: this.props.auditFindingCrud.data
    })
  }

  componentWillReceiveProps(nextProps){
    this.setState(prevState => ({
      ...prevState,

      // auditFinding: this.state.auditFinding.images.length > nextProps.auditFindingCrud.data.images.length ?  this.state.auditFinding : nextProps.auditFindingCrud.data
        // auditFinding: this.state.auditFinding.images.length > nextProps.auditFindingCrud.data.images.length ?  this.state.auditFinding : nextProps.auditFindingCrud.data

    }))
  }

  toggleDelModal = (title='', item = [], index) => {
    this.setState({
      delModal: !this.state.delModal,
      modalTitle: title,
      picId: item.id,
      picIndex: index
    });
  }
  updateForm = (type, value) => {
    this.setState(prevState => ({
      ...prevState,
      auditFinding: {
        ...prevState.auditFinding,
        [type]: value
      },
      error: {
        ...prevState.error,
        [type]: ''
      }
    }))
  }

  deleteImgFn = (picId, picIndex) => {
    this.props.deleteImgFn(picId, picIndex, this.props.auditFindingCrud.type)
  }

  changePicture = (item, file, picIndex) => {
    let data = Object.assign(this.props.auditFindingCrud.data);
    if(item.id == 0){
      let reader = new FileReader();
      reader.onload = (e) => {
      this.setState(prevState => ({
        ...prevState,
        auditFinding: {
          ...prevState.auditFinding,
          images: [ ...prevState.auditFinding.images].map((data, index) => index == picIndex ? { id: 0 , image: reader.result} : data )
        },
        fileArr:  [...prevState.fileArr].map((data, index) => index == picIndex ? file[0] : data ),
      }))
    };
    reader.readAsDataURL(file[0]);
    let fileArr = [...data.images].map((dataFinding, index) =>
      index == picIndex ?
     { id: 0 , image: URL.createObjectURL(file[0])} : dataFinding
     )
    this.props.changePicture(item.id, fileArr, this.props.auditFindingCrud.type)
    }else{
      this.props.changePicture(item.id, file, this.props.auditFindingCrud.type)
    }
  }

  addAndChangeFinding = (sectionId, dummy=null, files) => {
    let fileArrMulti = [];
    let displayImageArr = [];
    let imageArr = [];
    let data = Object.assign(this.props.auditFindingCrud.data);
    if(parseInt(data.images.length + files.length) > 5){
      toastFlashMessage('You can Upload upto '+ parseInt(5- data.images.length)  +' files',  'warning');
      this.setState({
        dragOver:false,
      });
      return false;
    }
    if(this.props.auditFindingCrud.type == 'edit'){
      this.props.editFindingImageSave(this.state.auditFinding, this.props.auditFindingCrud.type, files, sectionId, files);
    }else{
      if (files) {
        for (let i = 0; i < files.length; i++) {
          let file = files[i];
          if (!file.type.match('image.*')) {
            toastFlashMessage( file.name + ' is not Valid Format', 'warning');
             continue;
           }
           fileArrMulti = [...fileArrMulti, file];
           if(i <= 0){
            displayImageArr = [{ id: 0 , image:  URL.createObjectURL(file)}]
           }else{
            displayImageArr = [...displayImageArr, { id: 0 , image:  URL.createObjectURL(file)}]
           }
        }
        this.setState(prevState => ({
          ...prevState,
          fileArr:  [...prevState.fileArr, ...fileArrMulti],
        }))
        imageArr = [...data.images, ...displayImageArr]
      }
      this.props.editFindingImageSave(imageArr, this.props.auditFindingCrud.type, imageArr, sectionId, files);
    }
    this.setState(prevState => ({
       ...prevState,
        dragOver: false,
    }))
  }

  addEditFinding = (e, sectionId) => {
    e.preventDefault();
    const validateNewInput = {
      remarks: checkString({value: this.state.auditFinding.remarks, required: true, minLength: '', maxLength: '', message: 'Please enter Description'}),
      finding_type: checkString({value: this.state.auditFinding.finding_type, required: true, minLength: '', maxLength: '', message: 'Please select Damage Type'})
    };

    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
      this.props.addEditFinding(this.state.auditFinding, this.props.auditFindingCrud.type, this.state.fileArr, sectionId);
    }else{
      this.setState({
        error: validateNewInput
      });
    }
  }

   onDragOver = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
    this.setState({
      dragOver:true,
    });
  }

  onFocusOut = () =>{
    this.setState({
      dragOver: false,
    })
  }

  onDrop = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    this.setState({
      dragOver: false,
    })


  }

  onDragOver = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    this.setState({
      dragOver:true,
    });


  }

  onFocusOut = () =>{
    this.setState({
      dragOver: false,
    })
  }

  uploadFiles = (e) => {
    // e.preventDefault();
    // e.stopPropagation();
    this.setState({
      dragOver: false,
    })

  }
  toggle = () =>{ this.setState({ 
    dropdownOpen: !this.state.dropdownOpen
   }); }


  render(){
    const { auditFinding, fileArr,damageType,classifications,category,repairType, error } = this.state;
    if(!Object.keys(auditFinding).length ){
      return null
    }
    const { type } = this.props.auditFindingCrud;
    return(
      <form className="technical-form" onSubmit={(e) => this.addEditFinding(e, this.props.sectionId)}>
        <div className="sidebar-form" style={{paddingBottom: '90px'}}>
          <Row>
            <Col md="12" className="image-row">
              <ul className="list-inline flex-centered">
                {
                  this.props.auditFindingCrud.data.images.map((item, index) =>
                  <ImageCard
                    actionType = {type}
                    picture={item}
                    openSlider = {() => this.props.openSlider(this.props.auditFindingCrud.data.images, index)}
                    toggleDelModal={() => this.toggleDelModal('Are you sure to delete?' , item, index)}
                    options = {this.props.formInfo && this.props.formInfo.current_form && this.props.formInfo.current_form.permissions.can_save ? ['R', 'C', 'E','V']: []}
                    editImage = {() => this.props.editFindingImage({title: this.props.title, flag: true, imageUrl: item.image, imageId: item.id})}
                    changePicture = {(file) => this.changePicture(item, file, index)} />
                )}
                { (this.props.formInfo && this.props.formInfo.current_form && this.props.formInfo.current_form.permissions.can_save) && parseInt(this.props.auditFindingCrud.data.images.length ) < 5 ?
                  <li className="list-inline-item dropable-zone"
                    onDragOver={(evt) => this.onDragOver(evt)}
                    onDragLeave={() => this.onFocusOut()}
                    onDrop={(e) => browserName.toLowerCase() == 'ie' ? this.addAndChangeFinding(this.props.sectionId, null ,e.dataTransfer.files) : null}
                    style={ this.state.dragOver ? { background: 'rgba(0,0,0,0.7)'} : { background: 'transparent'}} >
                    <div className="content-drop-blk" >

                        <input
                          type="file"
                          multiple
                          accept='image/*'
                          style={{position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', opacity: 0, zIndex: 10}}
                          onChange={(e) => this.addAndChangeFinding(this.props.sectionId, null ,e.target.files)}
                        />
                      <div>
                        <img width="30" src={imgStoragePath+"content_attachment.png"} alt="Attachment" />
                        <p>
                          Drag and Drop Pictures Here
                          or  Click here to choose the Pictures
                        </p>
                      </div>
                    </div>
                    <span className="img-info-msg">
                      Recommended Picture dimension 400 X 400 Pixels
                    </span>
                  </li> : null
                }
              </ul> 

            </Col>
          </Row>
          <Row>
            <Col md="4" style={{marginBottom: '20px'}}>
            <label className="for-label"  > Damage Type<sup className="reqStar"> * </sup></label>
            <FieldSelect
                  placeholder='Select Type'
                  keyParam="finding_type"
                  value= {auditFinding.finding_type ? auditFinding.finding_type :''}
                  type={this.props.formInfo && this.props.formInfo.current_form && this.props.formInfo.current_form.permissions.can_save ? 'edit':'view'}
                  options = {damageType}
                  updateField={this.updateForm}
                  name = {damageType}
                  label = "label"
                  valueKey = "value"
                  
            />
             <h6 className="error-msg">{this.state.error.finding_type}</h6>
          
            </Col>
            <Col md="4" style={{marginBottom: '20px'}}>
              {
                GetInputField(
                  'string',
                  <label className="for-label">Damage Dimension</label>,
                  { type: (this.props.formInfo && this.props.formInfo.current_form && this.props.formInfo.current_form.permissions.can_save) ? 'edit':'view',
                    value: auditFinding.damage_dimension ? auditFinding.damage_dimension : '',
                    error: '',
                    updateForm: (value) => this.updateForm('damage_dimension', value)
                  }
                )
              }
            </Col>
            <Col md="4" style={{marginBottom: '20px'}}>
              {
                GetInputField(
                  'string',
                  <label className="for-label">Damage Within Limit</label>,
                  { type: (this.props.formInfo && this.props.formInfo.current_form && this.props.formInfo.current_form.permissions.can_save) ? 'edit':'view',
                    value: auditFinding.damage_limit,
                    error: '',
                    updateForm: (value) => this.updateForm('damage_limit', value)
                  }
                )
              }
            </Col>
            <Col md="12" style={{marginBottom: '20px'}}>
              {
                GetInputField(
                  'textarea',
                  <label className="for-label">Description {type != 'view'?<sup className="reqStar"> * </sup>:null}</label>,
                  { type: (this.props.formInfo && this.props.formInfo.current_form && this.props.formInfo.current_form.permissions.can_save) ? 'edit':'view',
                    value: auditFinding.remarks,
                    error: this.state.error.remarks,
                    updateForm: (value) => this.updateForm('remarks', value)
                  }
                )
              }
            </Col>

            <Col md="4" style={{marginBottom: '20px'}}>
            <label className="for-label" >Classification</label>
            <FieldSelect
                  placeholder='Classification'
                  keyParam="classifications"
                  value= {auditFinding.classifications ? auditFinding.classifications :'' }
                  type={this.props.formInfo && this.props.formInfo.current_form && this.props.formInfo.current_form.permissions.can_save ? 'edit':'view'}
                  options = {classifications}
                  updateField={this.updateForm}
                  name = {classifications}
                  labelKey = "label"
                  valueKey = "value"
            />
          
            </Col>
            <Col md="4" style={{marginBottom: '20px'}}>
            <label className="for-label">Category</label>
            <FieldSelect
                  placeholder='Category '
                  keyParam="category"
                  value= {auditFinding.category ? auditFinding.category :'' }
                  type={this.props.formInfo && this.props.formInfo.current_form && this.props.formInfo.current_form.permissions.can_save ? 'edit':'view'}
                  options = {category}
                  updateField={this.updateForm}
                  name = {category}
                  labelKey = "label"
                  valueKey = "value"

            />
          
            </Col>
            <Col md="4" style={{marginBottom: '20px'}}>
            <label className="for-label">Repair Type</label>
            <FieldSelect
                  placeholder='Repair Type'
                  keyParam="repair_type"
                  value= {auditFinding.repair_type ? auditFinding.repair_type :'' }
                  type={this.props.formInfo && this.props.formInfo.current_form && this.props.formInfo.current_form.permissions.can_save ? 'edit':'view'}
                  options = {repairType}
                  updateField={this.updateForm}
                  name = {repairType}
                  labelKey = "label"
                  valueKey = "value"
            />
          
            </Col>
            <Col md="6" style={{marginBottom: '20px'}}>
                {
                  GetInputField(
                    'string',
                    <label className="for-label">AMM / SRM Reference</label>,
                    { type: (this.props.formInfo && this.props.formInfo.current_form && this.props.formInfo.current_form.permissions.can_save) ? 'edit':'view',
                      value: auditFinding.arm_srm_reference,
                      error: '',
                      updateForm: (value) => this.updateForm('arm_srm_reference', value)
                    }
                  )
                }
            </Col>
            <Col md="6" style={{marginBottom: '20px'}}>
              {
                GetInputField(
                  'string',
                  <label className="for-label">D&D WO Reference</label>,
                  { type: (this.props.formInfo && this.props.formInfo.current_form && this.props.formInfo.current_form.permissions.can_save) ? 'edit':'view',
                    value: auditFinding.dd_wo_reference,
                    error: '',
                    updateForm: (value) => this.updateForm('dd_wo_reference', value)
                  }
                )
              }
            </Col>
           
            <Col md="12" style={{marginBottom: '20px'}}>
              {
                GetInputField(
                  'textarea',
                  <label className="for-label">Corrective Action </label>,
                  { type: (this.props.formInfo && this.props.formInfo.current_form && this.props.formInfo.current_form.permissions.can_save) ? 'edit':'view',
                    value: auditFinding.corrective_actions,
                    error: this.state.error.corrective_actions,
                    updateForm:(value)=>this.updateForm("corrective_actions", value)
                  }
                )
              }
            </Col>
            <Col md="12" style={{marginBottom: '20px'}}>
              {
                GetInputField(
                  'textarea',
                  <label className="for-label">Threshold / Intervals </label>,
                  { type: (this.props.formInfo && this.props.formInfo.current_form && this.props.formInfo.current_form.permissions.can_save) ? 'edit':'view',
                    value: auditFinding.intervals,
                    error: this.state.error.intervals,
                    updateForm: (value) => this.updateForm('intervals', value)
                  }
                )
              }
            </Col>

          </Row>
        </div>
        <DeleteModal isOpen={this.state.delModal} toggle={() => this.toggleDelModal()} title={this.state.modalTitle} className="modal-tform-delete-pictures modal-dialog-centered">
          <ul className="list-inline">
            <li className="list-inline-item">
              <button type="button" className="btn btn-danger" onClick={() => {this.toggleDelModal();this.deleteImgFn(this.state.picId, this.state.picIndex)}}>Yes</button>
            </li>
            <li className="list-inline-item">
              <button type="button" className="btn btn-primary" onClick={() => this.toggleDelModal('')}>No</button>
            </li>
          </ul>
        </DeleteModal>
        <div className="edit-sidebar-footer" style={{width: '840px', position: 'fixed', bottom: '0px', right: '0px',left: 'auto'}}>
          <ul className="list-inline">
            { this.props.formInfo && this.props.formInfo.current_form && this.props.formInfo.current_form.permissions.can_save ?
              <li className="list-inline-item">
                <input  disabled={this.props.findingLoader}
                className="primary-btn" type="submit" value={this.props.findingLoader ?  "Processing..." : "Save" }/>
              </li>:null
            }
            <li className="list-inline-item">
              <Link onClick={this.props.toggleEditSideBar}> Cancel </Link>
            </li>
          </ul>
        </div>
      </form>
    )
  }
}
