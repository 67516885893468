import React from 'react';
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import noRecodImage from '../../assets/img/no-results.png'
const EmptyCollection = ({icon, title, description,className, buttonText, onAddClick}) => {
  return(
    <div className={className ? className :"empty-collection-ui"}>
      <img style={{width:'320px'}} src={noRecodImage} alt="" />
      { title ? <h4>{title}</h4>:null }
      { description ? <p>{description}</p> : null }
      { buttonText ?
        <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={onAddClick}>{buttonText}</Button>:null
      }
    </div>
  )
}

export default EmptyCollection;
