import React, { useState, Fragment } from 'react';
import { Link, withRouter } from 'react-router';
import { useSnackbar } from 'notistack';
import { Button,CircularProgress,Dialog,DialogActions,DialogContent,DialogTitle,Grid,TextField} from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
import { GetApp } from '@material-ui/icons';
import { checkApiStatus ,downloadFileType, getLocalStorageInfo} from '../../../utils_v2';
import { globalPostService,globalExportService } from '../../../utils_v2/globalApiServices';
import { globalStaticExportService } from '../../../globalServices';
import { trackActivity } from '../../../utils/mixpanel';
import GetAppIcon from '@material-ui/icons/GetApp';
import moment from 'moment';
const ImportViaSheet = ({params, getResponseBack,getPRSCategories,getCurrentFormDetail}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false)
    const [modal, setModal] = useState(false);
    const [files, setFiles] = useState([]);
    const importSheet = () => {
        console.log('params',params);
        let formData = new FormData();    
        formData.append('file', files[0]);
        setLoading(true);
        globalPostService(`technical/workorder/${params.workOrderSlug}/import-gap-report/?asset_type=${params.type}`, formData)
        .then(response => {
            if(checkApiStatus(response)){
                setModal(false);setFiles([]);
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}})
                getResponseBack();
                getPRSCategories();
                getCurrentFormDetail();
                trackActivity('Project Management', {
                    event_type: 'T011 PRS Sheet Imported',
                    page_title: 'T011 Form',
                    asset_type: params?.type,
                    workorder_slug: params?.workOrderSlug,
                    form_slug: params?.formSlug,
                    success_msg: response.data.message
                })
            }else{
                enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
                trackActivity('Project Management', {
                    event_type: 'T011 PRS Sheet Import Failed',
                    page_title: 'T011 Form',
                    asset_type: params?.type,
                    workorder_slug: params?.workOrderSlug,
                    form_slug: params?.formSlug,
                    error_msg: response.data.message
                })
            }
            setLoading(false);
        });
    }
    const downloadsampleTemplate =()=>{
        globalExportService('technical/v2/sections/sampleDownload/')
         .then(response=>{
                downloadFileType(response, 'Sample_Template.', 'xls' )
         })   
        }
    const exportSampleFormReport = (extension) => {
        enqueueSnackbar('T011 Sample Report Downloading ...', { variant: 'info', anchorOrigin: { horizontal: 'right', vertical: 'bottom' } })
        globalStaticExportService(`8223/technical/workorder/${params.workOrderSlug}/gap-report-sections/?asset_type=${params.type}&download=xls&blank=True`)
            .then(response => {
                downloadFileType(response, (`T011_sample_${moment().format('YYYY-MM-DD')}.`), 'xls');
                closeSnackbar();
                trackActivity('Project Management', {
                    event_type: 'Sample File Exported',
                    page_title: 'T011 Form',
                    project_slug: params.project_slug ? params.project_slug : '',
                    file_name: `T011_${getLocalStorageInfo().defaultLessor.name}_${moment().format('YYYY-MM-DD')}`,
                    file_extension: extension,
                    success_msg: 'Sample Report exported Successfully'
                })
            });
    }
    return(
        <Fragment>
            <Button onClick={() => {setModal(true); trackActivity('Project Management', {event_type: 'Item Clicked',page_title: 'T011 Form', form_slug: params?.formSlug, event_desc:'Clicked on Import Button'})}} variant="outlined" color="primary" size="small">Import</Button>
            { modal ?
                <Dialog
                    open={modal}
                    onClose={() => setModal(false)}
                >
                    <DialogTitle id="scroll-dialog-title">Import PRS Sheet</DialogTitle>
                    <DialogContent dividers={true}>
                        <div style={{ width: "500px", textAlign:'center' }}>
                            <div style={{ width: "400px", margin:'0 auto' }}>
                                <DropzoneArea
                                    acceptedFiles={['.xls, .xlsx']}
                                    filesLimit={1}
                                    maxFileSize={10000000}
                                    showPreviewsInDropzone={false}
                                    useChipsForPreview={true}
                                    showPreviews={ true }
                                    dropzoneClass="drag-drop-cnt"
                                    showAlerts={["error", "info"]}
                                    clearOnUnmount={true}
                                    alertSnackbarProps={{ anchorOrigin: { vertical: "top", horizontal: "right", }, }}
                                    onChange={(files) => setFiles(files)}
                                />
                            </div>
                        </div>
                        <Grid item xs={12} md={12}>
                                <label>
                                    <p style={{width:"490px",fontSize:"12px"}}>Note:- Please Export the Report using EXPORT REPORT button in the listing. Update the same and import for changes to reflect in SPARTA</p>
                                    <span className='download-sample-temp' onClick={exportSampleFormReport}> <GetAppIcon /> Download Sample Template </span>
                                </label>
                         </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setModal(false)} size='small' color="primary">Cancel</Button>
                        <Button disabled={!files.length || isLoading} onClick={importSheet} variant="contained" size='small' color="primary">
                            {isLoading ? <CircularProgress size={20} />:'Submit'}
                        </Button>
                    </DialogActions>
                </Dialog>:null
            }
            
        </Fragment>
    )
}
export default withRouter(ImportViaSheet);