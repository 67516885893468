import React from 'react';
import { Grid } from "@material-ui/core";
import CustomFields from '../shared/CustomFields';
import { CkEditorText } from '../../../shared_elements';
import { regexConstants } from '../../../constants/regEx';

const EngineBoreScopeDetail = ({ engine,params,updatePermission, error, onFieldChange}) => {
    const fields = [
        { name:"date_of_inspection",label:"Date Of Inspection",type: "date"},
        { name:"inspection_performed_by",label:"Inspection Performed By",type: "text",inputProps:{ maxLength: 150}},
        { name:"inspection_tsn",label:"TSN",type: "text", regex: regexConstants.decimalnodotstart,inputProps:{ maxLength: 10}},
        { name:"inspection_csn",label:"CSN",type: "text",regex: regexConstants.onlyNumeric,inputProps:{ maxLength: 10}},
        { name:"inspection_tslv",label:"TSLSV",type: "text", regex: regexConstants.decimalnodotstart,inputProps:{ maxLength: 10}},
        { name:"inspection_cslv",label:"CSLSV",type: "text",regex: regexConstants.onlyNumeric,inputProps:{ maxLength: 10}},
        { name:"inspection_tsr",label:"TSR",type: "text",regex: regexConstants.decimalnodotstart,inputProps:{ maxLength: 10}},
        { name:"inspection_csr",label:"CSR",type: "text",regex: regexConstants.onlyNumeric,inputProps:{ maxLength: 10}},
        { name:"inspection_tso",label:"TSO",type: "text",regex: regexConstants.decimalnodotstart,inputProps:{ maxLength: 10}},
        { name:"inspection_cso",label:"CSO",type: "text", regex: regexConstants.onlyNumeric,inputProps:{ maxLength: 10}},
        { name:"inspection_tshi",label:"TSHSI",type: "text",regex: regexConstants.decimalnodotstart,inputProps:{ maxLength: 10}},
        { name:"inspection_cshi",label:"CSHSI",type: "text",regex: regexConstants.onlyNumeric,inputProps:{ maxLength: 10}}]
        return(
        <div className="project-management-modal">
            <div style={{height :params?.workOrderSlug ? updatePermission ?(window.innerHeight - 296) + 'px' : (window.innerHeight - 105) + 'px' :  (window.innerHeight - 312) + 'px', maxHeight: params?.workOrderSlug ? (window.innerHeight -266) + 'px' :  (window.innerHeight - 310) + 'px', overflow: 'auto', padding: '20px', position: '', marginLeft: '0px' }}>
                <Grid container spacing={1}>
                    <CustomFields gridSize={3}  fields={fields} fieldData={engine}  onFieldChange={onFieldChange} />
                    <Grid style={{marginTop : "16px"}} item md={12}>
                        {engine && Object.keys(engine)?.length> 0 &&<CkEditorText 
                        htmlData={engine?.inspection_details || ""}
                        onChangeData={(data) => onFieldChange("inspection_details", data)}
                        /> }
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}
export default EngineBoreScopeDetail;