import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { TableRow, TableCell, Tooltip, IconButton } from '@material-ui/core';
import moment from 'moment'
import EditIcon from "@material-ui/icons/Edit"
import DeleteIcon from "@material-ui/icons/Delete"
import { backendDateFormat, displayDateFormat, displayDateTime12HrsFormatShort } from '../../../constants';
import { onCheckPermission } from '../../../utils_v2';
const TagsList = ({item, editTag, onDelete}) => {
  return(
    <TableRow>
        {/* <TableCell>{item.id}</TableCell> */}
        <TableCell style={{width:'300px'}}>{item.name}</TableCell>
        <TableCell>{item.created_by && item.created_by.name ? item.created_by.name : '--'}</TableCell>
        <TableCell>{moment(item.created_at).format(displayDateTime12HrsFormatShort)}</TableCell>
        <TableCell>{moment(item.updated_at).format(displayDateTime12HrsFormatShort)}</TableCell>
        <TableCell align="right" >
          { onCheckPermission('project_management','template','U') ?
          <Tooltip title="Edit">
            <IconButton size="small" color="primary" onClick={() => editTag()} >
              <EditIcon color="primary" fontSize="small" />
            </IconButton>
          </Tooltip>:null}
          { onCheckPermission('project_management','template','D') ?
          <Tooltip title="Delete">
            <IconButton size="small" color="primary" onClick={onDelete} >
              <DeleteIcon color='secondary' fontSize="small" />
            </IconButton>
          </Tooltip>:null}
        </TableCell>
    </TableRow>
  )
}
export default TagsList;
