import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { browserHistory, Link } from 'react-router';
import { useSnackbar } from 'notistack';
import { Paper, TextField, Grid, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { createEngine } from '../';
import { helperFunc } from '../helperFunc';
import EngineWorkOrder from '../containers/EngineWorkOrder';
import { getLocalStorageInfo } from '../../../utils_v2';

const AddEngineWorkOrder = ({ params }) => {
    const { enqueueSnackbar } = useSnackbar();
    const userInfo = getLocalStorageInfo()
    const [isLoading, setLoading] = useState(false);
    const [isButtonLoading, setButtonLoading] = useState(false);
    const [engine, setEngine] = useState(createEngine);
    const [enginesList, setEnginesList] = useState([]);
    const [engineTypes, setEngineTypes] = useState([]);
    const [projectData, setProjectData] = useState({});
    const [aircraftTypes, setAircraftTypes] = useState([]);
    const [forms, setForms] = useState([]);
    const [pltConstants, setPltConstants] = useState([]);
    const [error, setError] = useState({});
    const [tabIndex, setTabIndex] = useState('engine-current-details')
    useEffect(() => {
        getEngineForms();
        getPlatformConstants();
        getAircraftTypes();
        getEngines();
        getEngineTypes();
        if (params?.workOrderSlug) { fetchEngineWorkOrderDetails(); getProjects() }
    }, [params])
    useEffect(() => {
        if (engine?.location) getLocationDetails(engine?.location);
    }, [engine?.location])
    const getProjects = () => {setLoading(true); helperFunc.fetchApi(`/technical/project/${params.project_slug}/`, "get", {}, (data) => { setProjectData(data?.responseData);setLoading(false) }); }
    const updatePermission = isLoading ? false : params?.workOrderSlug && Object.keys(projectData).length>0&&   projectData?.formPermission?.update?.value !== undefined && (projectData?.formPermission?.update?.value === 2 ||projectData?.formPermission?.update?.value === 1) ? false : true
    const getEngineTypes = () => { helperFunc.fetchApi("console/engine-types/", "get", {}, (data) => { setEngineTypes(data?.responseData?.engineTypes); }); }
    const getAircraftTypes = () => { helperFunc.fetchApi("console/aircraft-types/", "get", {}, (data) => { setAircraftTypes(data?.responseData?.aircraftTypes); }); }
    const getEngineForms = () => { helperFunc.fetchApi("technical/forms/", "get", { form_type: 2 }, (data) => { setForms(data?.responseData); }); }
    const getEngines = () => {setLoading(true); helperFunc.fetchApi(`console/engine-esn-list/`, "get", { switcher: true }, (data) => { setLoading(true); setEnginesList(data?.responseData?.engines); setLoading(false) }); }
    const getPlatformConstants = () => {
        helperFunc.fetchApi("console/get-constants/", "post", { constant_types: ["engine_position"] },
            (data) => {
                const updatedConstant = data?.responseData?.length > 0 && data?.responseData?.map((constant) => ({ ...constant, id: constant?.value, name: constant?.label, }));
                setPltConstants(updatedConstant);
            }
        );
    }
    const fetchEngineWorkOrderDetails = () => {
        setLoading(true);
        helperFunc.fetchApi(`/technical/project/${params.project_slug}/engine-workorder/${params.workOrderSlug}/`, "get", {}, (data) => {
            setEngine({
                ...data?.responseData,
                tsn: String(data?.responseData?.tsn) || "",
                csn: String(data?.responseData?.csn) || "",
                mr: String(data?.responseData?.mr) || "",
                end_of_lease: String(data?.responseData?.end_of_lease) || "",
                lease_contract: String(data?.responseData?.lease_contract) || "",
                qme: String(data?.responseData?.qme) || ""
            });
        })
    }
    const getEngineDetails = (slug) => {
        setLoading(true);
        helperFunc.fetchApi(`technical/engine/${slug}/details/`, "get", {}, (data) => {
            if (data?.responseData && Object.keys(data?.responseData).length > 0) {
                setLoading(false);
                setEngine({
                    ...engine,
                    ...data?.responseData,
                    create_esn: data?.responseData?.create_esn ? data?.responseData?.create_esn : createEngine.create_esn,
                    tsn: String(data?.responseData?.tsn) || null,
                    csn: String(data?.responseData?.csn) || null,
                });
            }
        }, (error) => { enqueueSnackbar(error, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "top" }, }); setLoading(false); });
    }
    const onFieldChange = (key, value,fieldType) => {
        setError({})
        if (fieldType === "date" && value === "Invalid") {
            setEngine({ ...engine, [key]: null })
        } else {
            if (key === "engine_id" && engine?.create_esn === false) {
                setEngine({ ...engine, esn: value?.esn, engine_id: value?.id });
                if (value?.slug) getEngineDetails(value?.slug);
            } else { setEngine({ ...engine, [key]: value }); }
        }
        
    }
    const getLocationDetails = async (address) => {
        try {
            const results = await geocodeByAddress(address);
            const countryComponent = results[0]?.address_components.find((component) =>
                component.types.includes("country")
            );
            const countryName = countryComponent ? countryComponent.long_name : "Unknown Country";
            const latLng = await getLatLng(results[0]);
            setEngine({ ...engine, country: countryName, lat: latLng.lat, lang: latLng.lng });
        } catch (error) {
            console.error("Error fetching coordinates or country:", error);
        }
    }
    const onAddEngine = () => {
        const isCheckFutureDate = (date) =>  moment(date, "YYYY-MM-DD", true).isAfter(moment()); 
        let validationInputs = {
            esn: engine?.esn?.trim()?.length ? '' : 'Please enter Serial Number',
            engine_type_id: engine?.engine_type_id ? '' : 'Please select Engine Type',
            country: engine.create_esn ? engine?.country?.trim()?.length ? '' : 'Please enter Country' : '',
            engine_manufacturing_date: engine?.engine_manufacturing_date ? isCheckFutureDate(engine?.engine_manufacturing_date) ? "Future date is not valid.":   "" :   "Please enter Manufacturing Date" ,
            tsn: engine?.tsn === 0 || engine?.tsn?.trim()?.length ? '' : 'Please enter TSN',
            csn: engine?.csn === 0 || engine?.csn?.trim()?.length ? '' : 'Please enter CSN',
        }
        if (Object.keys(validationInputs).some((k) => { return validationInputs[k] !== '' }) ){
            setError(validationInputs);
            setTabIndex("engine-current-details")
        }else if(engine?.engine_manufacturing_date && isCheckFutureDate(engine?.engine_manufacturing_date)){
            enqueueSnackbar("Future Date is not valid as Manufacturing Date", { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
        }else if(engine?.date_of_installation && isCheckFutureDate(engine?.date_of_installation)){
            enqueueSnackbar("Future Date is not valid as Date of Installation", { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
        } else if(engine?.date_of_inspection && isCheckFutureDate(engine?.date_of_inspection)){
            setTabIndex("borescope-inspection-details")
            enqueueSnackbar("Future Date is not valid as Date of Inspection", { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
        } else if(engine?.date_of_installation && moment(engine.date_of_installation).diff(moment(engine.engine_manufacturing_date),'days')<0){
            enqueueSnackbar("Date of Installation should be greater than Manufacturing Date", { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
        }else if(engine?.date_of_inspection && moment(engine.date_of_inspection).diff(moment(engine.engine_manufacturing_date),'days')<0){
            setTabIndex("borescope-inspection-details")
            enqueueSnackbar("Date of Inspection should be greater than Manufacturing Date", { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
        }else{
            setButtonLoading(true)
            let payload = {
                ...engine,
                qme: engine?.qme ? Number(engine?.qme) : null,
                mr: engine?.mr ? Number(engine?.mr) : null,
                lease_contract: engine?.lease_contract ? Number(engine?.lease_contract) : null,
                end_of_lease: engine?.end_of_lease ? Number(engine?.end_of_lease) : null,
                tsn: engine?.tsn ? Number(engine?.tsn) : null,
                csn: engine?.csn ? Number(engine?.csn) : null,
            }
            let apiUrl
            if (params?.workOrderSlug) {
                apiUrl = `engine-workorder/${params.workOrderSlug}`
            } else {
                if (engine?.create_esn === false) { delete payload.create_esn }
                apiUrl = `create-engine-workorder`
            }
            helperFunc.fetchApi(`/technical/project/${params.project_slug}/${apiUrl}/`, params?.workOrderSlug ? "put" : "post", { data: payload }, (data) => {
                setButtonLoading(false)
                enqueueSnackbar(data?.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                browserHistory.push('/technical/project/view/' + params.project_slug);
            }, (error) => { enqueueSnackbar(error, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } }); setButtonLoading(false) }
            );
        }
      }

    const onMultiFieldChange = (index, field, value, componentKey) => {
        const updatedRows = [...engine[componentKey]];
        updatedRows[index][field] = value;
        setEngine({ ...engine, [componentKey]: updatedRows })
    };
    const filteredOptions = forms?.filter(
        (option) => !engine?.forms.some((selected) => selected.id === option.id)
    );
   
    return (
        <div className='project-management-modal add-edit-work-order-modal' style={{ marginLeft: '34px', padding: ' 0px 0px 0px 30px', position: '', }}>
            <Link style={{ cursor: 'pointer', width: "110px", marginTop: "10px" }} className='flex-centered' to={"/technical/project/view/" + params.project_slug}>
                <ArrowBackIcon fontSize='medium' color='primary' /> <h5 style={{ color: '#3f51b5' }}>{params?.workOrderSlug ? "Edit ESN" : "Add ESN"}</h5>
            </Link>
            <Grid container spacing={1}>
                <Grid item md={2}></Grid>
            </Grid>
            <Grid container spacing={1} style={{ position: '' }}>
                {params?.workOrderSlug ? null : <Grid item md={12}>
                    <RadioGroup row aria-label="position" name="position" defaultValue="link">
                        <FormControlLabel value="link" control={<Radio checked={engine.create_esn === false} onChange={() => { setTabIndex("engine-current-details"); setError({}); setEngine({ ...createEngine, create_esn: false }) }} color="primary" size='small' />} label="Link Existing ESN" />
                        <FormControlLabel value="create" control={<Radio checked={engine.create_esn === true} onChange={() => { setTabIndex("engine-current-details"); setError({}); setEngine({ ...createEngine, create_esn: true }) }} color="primary" size='small' />} label="Create New ESN" /> 
                    </RadioGroup>
                </Grid>}
                <Grid item md={12} style={{ marginBottom: "10px" }}>
                    <Autocomplete
                        disabled={ params?.workOrderSlug &&updatePermission}
                        multiple
                        options={filteredOptions}
                        getOptionLabel={option => option.name}
                        name="forms"
                        value={engine?.forms || []}
                        onChange={(e, value) => onFieldChange('forms', value)}
                        renderInput={params => <TextField {...params} label="Forms" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                    />
                    {params?.workOrderSlug &&updatePermission  ?<div style={{}} className='general-notes'>
                        Forms can not be edited due to self assigned or no permission for Form update.
                    </div> 
                        : null
                    }
                </Grid>
            </Grid>
            <Paper>
                <EngineWorkOrder tabIndex={tabIndex} updatePermission={updatePermission} setTabIndex={setTabIndex} params={params} engine={engine} setEngine={setEngine}  onMultiFieldChange={onMultiFieldChange} error={error} setError={setError} onAddEngine={onAddEngine} enginesList={enginesList} engineTypes={engineTypes} aircraftTypes={aircraftTypes} pltConstants={pltConstants} onFieldChange={onFieldChange} isLoading={isLoading} isButtonLoading={isButtonLoading} />
            </Paper>
        </div>
    )
}
export default AddEngineWorkOrder;
