import React from 'react';
import { Link } from 'react-router';
import { Paper, Button, Tabs, Tab, CircularProgress } from '@material-ui/core';
import { EngineWorkOrderDetail, EngineInvoiceDetail, EngineBoreScopeDetail, EngineAttachments, EngineReferenceLinks } from '../components'

const EngineWorkOrder = ({engine,onMultiFieldChange,onAddEngine,updatePermission, onFieldChange, error,setError, enginesList, engineTypes, aircraftTypes, pltConstants, params,isLoading,isButtonLoading,setEngine,tabIndex,setTabIndex }) => {
    return (
        <>
            <div style={{ position: 'relative' }}>
                <div style={{ overflow: '', marginLeft: '' }}>
                    <Paper >
                        <Tabs
                            value={tabIndex}
                            onChange={(event, newValue) =>  { setTabIndex(newValue) }}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                        >
                            <Tab label={<span>Engine Current Details</span>} value="engine-current-details" />
                            {/* <Tab label={<span>Shop Visits</span>} value="shop-visits"/> */}
                            <Tab label={<span>MR and Invoice Details</span>} value="mr-and-invoice-details" />
                            <Tab label={<span>Borescope Inspection Details</span>} value="borescope-inspection-details" />
                            <Tab label={<span>Attachments</span>} value="attachments" />
                            <Tab label={<span>Reference Links</span>} value="reference-links" />
                        </Tabs>
                    </Paper>
                    {tabIndex === 'engine-current-details' ?
                        <EngineWorkOrderDetail engine={engine} params={params} updatePermission={updatePermission} error={error} enginesList={enginesList} engineTypes={engineTypes} aircraftTypes={aircraftTypes} pltConstants={pltConstants} onFieldChange={onFieldChange} isLoading={isLoading} /> : null
                    }
                    {/* {tabIndex === 'shop-visits' ?
                        <EngineShopVisit  /> : null
                    } */}
                    {tabIndex === 'mr-and-invoice-details' ?
                        <EngineInvoiceDetail  params={params} engine={engine} updatePermission={updatePermission}  error={error}  onFieldChange={onFieldChange}/> : null
                    }
                    {tabIndex === 'borescope-inspection-details' ?
                        <EngineBoreScopeDetail  engine={engine} params={params} updatePermission={updatePermission} error={error}  onFieldChange={onFieldChange} /> : null
                    }

                    {tabIndex === 'attachments' ?
                        <EngineAttachments engine={engine} setEngine={setEngine} updatePermission={updatePermission} params={params}   onMultiFieldChange={onMultiFieldChange}/>: null
                    }
                    {tabIndex === 'reference-links' ?
                       <EngineReferenceLinks engine={engine} params={params} updatePermission={updatePermission} setEngine={setEngine}   onMultiFieldChange={onMultiFieldChange} /> : null
                    }
                </div>
                <div className="divider" style={{ height: '51px' }}></div>
                <Paper square style={{ padding: '10px', borderTop: '1px solid #d7d7d7', position: 'absolute', bottom: '0', left: '0', width: '100%', background: '#fff', zIndex: 9 }}>
                    <ul className="list-inline" style={{ float: 'right' }}>
                        <li className="list-inline-item">
                            <Link to={"/technical/project/view/" + params.project_slug} ><Button disabled={isLoading} variant='outlined' color="primary" size="small">Cancel</Button></Link>
                        </li>
                        <li className="list-inline-item">
                            <Button disabled={isButtonLoading} onClick={onAddEngine} variant="contained" color="primary" size="small">{isButtonLoading ? <CircularProgress size={20} /> : "Save"}</Button>
                        </li>
                    </ul>
                </Paper>
            </div>
        </>
    )
}
export default EngineWorkOrder;