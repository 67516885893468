import React, { useState } from 'react'
import moment from 'moment';
import { Link } from 'react-router';
import { useSnackbar } from 'notistack';
import { Paper, Button, Table, TableHead, TableRow, TableCell, TableBody, Grid, IconButton, TextField, Tooltip } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { engineLinksHeader } from '..';
import DialogueWrapper from '../shared/Dialogue';
import { checkApiStatus, getLocalStorageInfo } from '../../../utils_v2';
import { imgStoragePath, displayDateFormat } from '../../../constants';
import { EmptyCollection } from '../../../shared';
import { globalDeleteService } from '../../../utils_v2/globalApiServices';
import "../assets/engine_workorder.scss"

const userInfo = getLocalStorageInfo();
const AddEditReferenceLinks = ({ engine, setEngine, referenceLinks,fieldData,
    setFieldData, toggleModalFn }) => {
         const { enqueueSnackbar } = useSnackbar();
    const { id, name, profile_pic } = userInfo.user
    const validateAndFormatUrl = (inputUrl) => {
        if (!/^https?:\/\//i.test(inputUrl)) {
            return `https://${inputUrl}`;
        }
        return inputUrl; 
    };

    const checkUrl = (url) => { try {
        const newUrl = new URL(url);
        if (!/\.[a-zA-Z]{2,}$/.test(newUrl.hostname)) {
          return false;
        }
        return newUrl.href; 
      } catch (_) {
        return false; 
      }}
    const handleAddRow = () => {
        const validatedUrl = validateAndFormatUrl(fieldData?.link);
        if( checkUrl(validatedUrl) === false){
            enqueueSnackbar("Please add valid Link", { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
            return;
        }
        const newLink = { link: validatedUrl, description: fieldData?.description, updated_at: moment(), user: { id: id, name: name, profile_pic: profile_pic } };
        if (engine?.reference_links?.length > 0) setEngine({ ...engine, reference_links: [...engine.reference_links, newLink] })
        else setEngine({ ...engine, reference_links: [newLink] });
        toggleModalFn()
        setFieldData({})
    };
    const onFieldChange = (keyParam, value) => setFieldData({ ...fieldData, [keyParam]: value })
    return (
        <DialogueWrapper  open={referenceLinks.addModal} disableButton={!Object?.keys(fieldData).length || fieldData?.link === ""}  toggleModalFn={toggleModalFn} dialogueTitle="Add Reference Link" handleSave={handleAddRow} dialogueWidth="400px" buttonLabel="Add">
            <Grid container spacing={1}>
                <Grid item md={12}>
                    <div >
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            label='Link'
                            value={fieldData?.link}
                            onChange={(e) => onFieldChange('link', e.target.value.trim())}
                            fullWidth
                            variant='outlined'
                            style={{marginTop : "10px", marginBottom : "10px"}}
                        />
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            label='Description'
                            value={fieldData?.description}
                            onChange={(e) => onFieldChange('description', e.target.value.trim())}
                            fullWidth
                            variant='outlined'
                            style={{ marginTop: "10px",marginBottom : "10px" }}
                        />
                    </div>
                </Grid>
            </Grid></DialogueWrapper>
    )
}
const EngineReferenceLinks = ({params, engine,updatePermission, setEngine ,onMultiFieldChange }) => {
    const [referenceLinks, setReferenceLinks] = useState({ addModal: false });
    const [fieldData, setFieldData] = useState({})
     const handleMultiDelete = (index, clickData) => {
            const updatedRows = [...engine?.reference_links];
            if (params?.workOrderSlug && clickData?.id) {
                globalDeleteService(`technical/engine-workorder/${params.workOrderSlug}/reference/${clickData?.id}/`, {})
                    .then(response => {
                        if (checkApiStatus(response)) {
                            updatedRows.splice(index, 1);
                            setEngine({ ...engine, reference_links: updatedRows });
                        }
                    })
            } else {
                updatedRows.splice(index, 1);
                setEngine({ ...engine, reference_links: updatedRows });
            }
    
        }
    return (
        <div >
            <Grid container style={{ justifyContent: 'flex-end', padding: '8px 4px 4px 10px' }}>
                <Button size='small' color='primary' variant='contained' onClick={() => setReferenceLinks({ addModal: true })}>
                    Add Reference Link
                </Button>
            </Grid>
            <Paper square style={{ height:params?.workOrderSlug  ? updatePermission ?(window.innerHeight - 339) + 'px' :   (window.innerHeight - 309) + 'px' : (window.innerHeight - 355) + 'px', overflow: 'auto' }}>
                <Table stickyHeader className='mui-table-format' style={{ height: 'auto', overflow: 'auto' }}>
                    <TableHead><TableRow>{engineLinksHeader?.map((headerField, index) => <TableCell key={headerField?.label + index} >{headerField?.label}</TableCell>)}</TableRow></TableHead>
                    <TableBody>
                        {engine?.reference_links?.length > 0 ? engine?.reference_links?.map((link, index) => (
                           <TableRow key={index}>
                                <TableCell>
                                    <TextField
                                        value={link.link}
                                        onChange={(e) => onMultiFieldChange(index, 'link', e.target.value,"reference_links")}
                                        fullWidth
                                        variant='outlined'
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        value={link.description}
                                        onChange={(e) => onMultiFieldChange(index, 'description', e.target.value,"reference_links")}
                                        fullWidth
                                        variant='outlined'
                                    />
                                </TableCell>
                                <TableCell><div className='d-flex align-items-center'> {link?.user?.profile_pic ? <img style={{ borderRadius: "50%" }} height={30} width={30} src={link?.user?.profile_pic} alt="user image" /> : <img src={imgStoragePath + 'user.png'} alt="user image" />} <div style={{ marginLeft: "5px" }}>  {link?.user?.name}<p style={{ fontSize: '11px', opacity: '0.8' }}>{moment().format(displayDateFormat)}</p></div> </div></TableCell>
                                <TableCell>
                                    <div className='flex-centered'>
                                       <Tooltip title="Link" arrow><Link target="_blank" to={link.link} ><i className="fas fa-external-link-alt" style={{ color: '#aaa', fontSize: '14px', position: 'relative', top: '0.5px' }}></i></Link></Tooltip> 
                                       <Tooltip title="Remove" arrow><IconButton onClick={() =>handleMultiDelete(index,link)}><CancelIcon color='secondary' fontSize="small" /> </IconButton></Tooltip> 
                                    </div>
                                </TableCell>
                            </TableRow>
                        )) : null}
                    </TableBody>
                </Table>
                {!engine?.reference_links?.length ?  <EmptyCollection title="No reference links found" className="empty-collection-ui-no-padding" /> : null}
                <AddEditReferenceLinks engine={engine} setEngine={setEngine} fieldData={fieldData} setFieldData={setFieldData} referenceLinks={referenceLinks} toggleModalFn={() =>{setFieldData({});setReferenceLinks({  addModal: false})}} />
            </Paper>
        </div>
    )
}

export default EngineReferenceLinks


